import React, { Component } from "react"
import { FormattedMessage, Link, injectIntl } from "gatsby-plugin-intl"
import {
  Container,
  Grid,
  Header as SUIHeader,
  Form,
  Button,
} from "semantic-ui-react"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"
import styled from "styled-components"
import Layout from "../components/layout"
import Image from "../components/image"
import Hero from "../components/hero"
import SEO from "../components/seo"
import Carosuel from "../components/carosuel"
import Icon from "../components/icon"
import Card from "../components/card"

const styles = {
  green: "#63962f",
}

const Header = styled(SUIHeader)`
  color: ${props => (props.primary ? "white" : styles.green)};
  font-weight: bold;
  font-size: 2.5em;
  text-align: center;
  padding-bottom: 0.2em;
`

const Paragraph = styled.p`
  color: ${props => (props.primary ? "white" : "black")};
  font-size: 1.2em;
  text-align: center;
`

const GridRow = styled(Grid.Row)`
  margin-top: 1em;
  background: ${props => (props.primary ? "white" : styles.green)};
  border-radius: 15px;
`

const FormattedForm = styled(Form)`
  label {
    text-align: left;
    padding: 1em !important;
    color: ${styles.green} !important;
    border-bottom: 2px solid #cacaca;
    font-size: 1.1em !important;
    font-weight: bold !important;
  }
  input,
  textarea {
    border-top: none !important;
  }
`

const FormattedInputContainer = styled(Form.Field)`
  background: white;
`

const FormContainer = styled(Grid.Column)`
  h2,
  h3,
  h4,
  p {
    color: white !important;
  }
  h2 {
    text-align: center;
    font-size: 2.5em !important;
    padding-bottom: 1em !important;
  }
  background: ${styles.green};
  @media (min-width: 1000px) {
    padding: 5em 9em !important;
  }

  @media only screen and (max-width: 767.99px) {
    padding: 2em 2em !important;
  }
  button {
    padding: 1.5em 4em !important;
    background: white !important;
    text-align: center !important;
    display: block !important;
    margin: auto !important;
    color: ${styles.green} !important;
  }
`

const FormattedContainer = styled(Container)`
  @media only screen and (min-width: 768px) {
    padding: 4em 6em 6em 6em;
  }
  @media only screen and (max-width: 767.99px) {
    padding: ${props => (props.form ? "3em 1em 2em 1em" : "2em 2em 4em 2em")};
  }
`

class IndexPage extends Component {
  state = { agency: "", name: "", email: "", message: "", gotcha: null }

  handleChange = event => {
    const name = event.target.name
    const value = event.target.value

    this.setState({
      [name]: value,
    })
  }

  submitForm = e => {
    e.preventDefault()
    axios
      .post("https://formspree.io/myppppqx", {
        agency: this.state.agency,
        name: this.state.name,
        _replyto: this.state.email,
        message: this.state.message,
        _subject: "Form Submission",
        _cc: "agents@iswp-china.com",
        _gotcha: this.state.gotcha,
      })
      .then(res => {
        toast.success("Form Successfully Sent")
        this.setState({
          agency: "",
          name: "",
          email: "",
          message: "",
        })
      })
      .catch(err => {
        toast.error("An error occurred when sending the form")
      })
  }

  render() {
    return (
      <Layout>
        <SEO
          title={this.props.intl.formatMessage({ id: "title" })}
          keywords={[`gatsby`, `application`, `react`]}
        />

        <Container fluid>
          <Hero />
          <Carosuel />
        </Container>

        <FormattedContainer fluid style={{ background: styles.green }}>
          <Grid stackable>
            <Grid.Row centered>
              <Grid.Column width="12">
                <Header as="h1" primary>
                  {this.props.intl.formatMessage({ id: "hm_title_1" })}
                </Header>
                <Paragraph primary>
                  {this.props.intl.formatMessage({ id: "hm_paragraph_1_1" })}
                </Paragraph>
                <Paragraph primary>
                  {this.props.intl.formatMessage({ id: "hm_paragraph_1_2" })}
                </Paragraph>
                <Paragraph primary>
                  {this.props.intl.formatMessage({ id: "hm_paragraph_1_3" })}
                </Paragraph>
              </Grid.Column>
            </Grid.Row>
            <GridRow columns="4" verticalAlign="middle" primary>
              <Grid.Column>
                <Card
                  icon="globe"
                  title="hm_card_1_1_title"
                  text="hm_card_1_1_text"
                  primary
                />
              </Grid.Column>
              <Grid.Column>
                <Card
                  icon="student"
                  title="hm_card_1_2_title"
                  text="hm_card_1_2_text"
                  primary
                />
              </Grid.Column>
              <Grid.Column>
                <Card
                  icon="people"
                  title="hm_card_1_3_title"
                  text="hm_card_1_3_text"
                  primary
                />
              </Grid.Column>
              <Grid.Column>
                <Card
                  icon="heart"
                  title="hm_card_1_4_title"
                  text="hm_card_1_4_text"
                  primary
                />
              </Grid.Column>
            </GridRow>
          </Grid>
        </FormattedContainer>

        <FormattedContainer fluid>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column>
                <Header as="h1">
                  {this.props.intl.formatMessage({ id: "hm_title_2" })}
                </Header>
                <Paragraph>
                  {this.props.intl.formatMessage({ id: "hm_paragraph_2_1" })}
                </Paragraph>
              </Grid.Column>
            </Grid.Row>
            <GridRow columns="4">
              <Grid.Column>
                <Card
                  icon="checkbox"
                  title="hm_card_2_1_title"
                  text="hm_card_2_1_text"
                />
              </Grid.Column>
              <Grid.Column>
                <Card
                  icon="child"
                  title="hm_card_2_2_title"
                  text="hm_card_2_2_text"
                />
              </Grid.Column>
              <Grid.Column>
                <Card
                  icon="building"
                  title="hm_card_2_3_title"
                  text="hm_card_2_3_text"
                />
              </Grid.Column>
              <Grid.Column>
                <Card
                  icon="stopwatch"
                  title="hm_card_2_4_title"
                  text="hm_card_2_4_text"
                />
              </Grid.Column>
            </GridRow>
          </Grid>
        </FormattedContainer>

        <FormattedContainer fluid style={{ background: styles.green }}>
          <Grid.Row>
            <Grid.Column>
              <Header as="h1" primary>
                {this.props.intl.formatMessage({ id: "hm_title_3" })}
              </Header>
              <Paragraph primary>
                {this.props.intl.formatMessage({ id: "hm_paragraph_3_1" })}
              </Paragraph>
            </Grid.Column>
          </Grid.Row>
        </FormattedContainer>

        <FormattedContainer form>
          <Grid centered>
            <Grid.Row>
              <FormContainer largeScreen="12" tablet="15" mobile="16">
                <Header as="h2">
                  <FormattedMessage id="hm_cf_title" />
                </Header>
                <FormattedForm onSubmit={e => this.submitForm(e)}>
                  <input
                    type="text"
                    name="gotcha"
                    style={{ display: "none" }}
                    value={this.state.gotcha}
                    onChange={this.handleChange}
                  />
                  <FormattedInputContainer>
                    <label>
                      {this.props.intl.formatMessage({ id: "hm_cf_an_input" })}
                    </label>
                    <input
                      type="text"
                      id="agency"
                      name="agency"
                      value={this.state.agency}
                      onChange={this.handleChange}
                    />
                  </FormattedInputContainer>
                  <FormattedInputContainer>
                    <label>
                      {this.props.intl.formatMessage({ id: "hm_cf_yn_input" })}
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                  </FormattedInputContainer>
                  <FormattedInputContainer>
                    <label>
                      {this.props.intl.formatMessage({ id: "hm_cf_ye_input" })}
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </FormattedInputContainer>
                  <FormattedInputContainer>
                    <label>
                      {this.props.intl.formatMessage({ id: "hm_cf_ym_input" })}
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      value={this.state.message}
                      onChange={this.handleChange}
                    />
                  </FormattedInputContainer>
                  <Button type="submit">
                    {this.props.intl.formatMessage({ id: "hm_cf_sb" })}
                  </Button>
                </FormattedForm>
                <Header as="h3">
                  {this.props.intl.formatMessage({ id: "hm_cf_info_title" })}
                </Header>
                <Header as="h4">
                  {this.props.intl.formatMessage({ id: "hm_cf_info_subtitle" })}
                </Header>
                <p>
                  {this.props.intl.formatMessage({ id: "hm_cf_info_text" })}
                </p>
                <p>
                  {this.props.intl.formatMessage({ id: "hm_cf_info_text2" })}
                </p>
                <p>
                  {this.props.intl.formatMessage({ id: "hm_cf_info_text3" })}
                </p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: this.props.intl.formatHTMLMessage({
                      id: "hm_cf_info_text4",
                    }),
                  }}
                ></p>
              </FormContainer>
            </Grid.Row>
          </Grid>
        </FormattedContainer>
        <ToastContainer />
      </Layout>
    )
  }
}

export default injectIntl(IndexPage)
