import React, { Component } from "react"
import { Helmet } from "react-helmet"
import Slider from "react-slick"
import "./carosuel.css"

class Carosuel extends Component {
  componentDidMount = () => {}

  render() {
    var settings = {
      dots: true,
      infinite: true,
      arrows: false,
      dots: false,
      autoplay: true,
      speed: 500,
      className: "inner-slide",
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 550,
          settings: "unslick",
        },
      ],
    }

    return (
      <>
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            charset="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
        </Helmet>
        <Slider {...settings} style={{ width: "70%" }}>
          <div>
            <img
              src="https://res.cloudinary.com/mystay-international/image/upload/v1660878057/ISWP-CHINA/LifeWorks-Logo-EN.png"
              width="250px"
              height="110px"
              alt="Moreneu Shepell"
            />
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/mystay-international/image/upload/q_auto/c_scale,w_235/v1527478735/ISWP-CHINA/BOSSA_FINAL_LOGO.png"
              width="235px"
              height="122px"
              alt="Beijing Overseas Study Service Association"
            />
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/mystay-international/image/upload/v1660878057/ISWP-CHINA/YAN%E7%94%BB%E6%9D%BF_1.png"
              width="235px"
              height="235px"
              alt="Guangdong Association for the Promotion of International Education (G.A.P.IE)"
            />
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/mystay-international/image/upload/q_auto/v1533185059/ISWP-CHINA/bycroft.png"
              width="365px"
              height="131px"
              alt="Bycroft Consulting"
            />
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/mystay-international/image/upload/q_auto/v1533185060/ISWP-CHINA/cossa.png"
              width="258px"
              height="122px"
              alt="Chinese Overseas Study Service Alliance"
            />
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/mystay-international/image/upload/v1533185060/ISWP-CHINA/mystay.png"
              width="345px"
              height="153px"
              alt="Mystay International"
            />
          </div>
        </Slider>
      </>
    )
  }
}

export default Carosuel
