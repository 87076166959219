import React, { Component } from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { Card as SUICard, Header } from "semantic-ui-react"
import styled from "styled-components"
import Icon from "./icon"

const StyledCard = styled(SUICard)`
  @media (min-width: 1000px) {
    min-height: ${props => (props.large ? "250px" : "200px")} !important;
  }
  color: ${props => (props.primary ? "#63962f" : "white")};
  svg {
    color: ${props => (props.primary ? "#63962f" : "white")};
  }
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  @media only screen and (max-width: 767.99px) {
    margin: auto !important;
  }
`

const StyledContent = styled(SUICard.Content)`
  border-top: none !important;
`
const StyledHeader = styled(Header)`
  color: ${props => (props.primary ? "#63962f" : "white")};
`

const StyledParagraph = styled.p`
  color: ${props => (props.primary ? "black" : "white")};
`

class Card extends Component {
  render() {
    return (
      <StyledCard primary={this.props.primary}>
        <StyledContent>
          <Icon icon={this.props.icon} />
        </StyledContent>
        <StyledContent title>
          <StyledHeader as="h2" primary={this.props.primary}>
            {this.props.intl.formatMessage({ id: this.props.title })}
          </StyledHeader>
        </StyledContent>
        <StyledContent>
          <StyledParagraph primary={this.props.primary}>
            {this.props.intl.formatMessage({ id: this.props.text })}
          </StyledParagraph>
        </StyledContent>
      </StyledCard>
    )
  }
}

export default injectIntl(Card)
