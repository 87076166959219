import React, { Component } from "react"
import PropTypes from "prop-types"
import { Container, Grid, Header } from "semantic-ui-react"
import { injectIntl } from "gatsby-plugin-intl"
import ReactPlayer from "react-player"
import styled from "styled-components"

const styles = {
  green: "#63962f",
}

const MainHeader = styled(Header)`
  color: ${styles.green};
  font-weight: bold;

  @media (min-width: 1000px) {
    font-size: 3.1em;
    width: 93%;
  }

  @media (min-width: 1300px) {
    font-size: 3.2em;
    width: 93%;
  }

  @media (min-width: 1600px) {
    font-size: 3.3em;
    width: 93%;
  }

  padding: 1em 1em 0em 1.5em;
  text-align: left;
`

const Subtitle = styled(Header)`
  font-weight: bold;
  padding-left: 2.1em;
  text-align: left;

  & p {
    margin-bottom: 5px;
    font-weight: bold;
  }

  & ul {
    margin-top: 0px;
  }

  & ul li {
    font-size: 0.9em;
  }

  @media (min-width: 1000px) {
    font-size: 2.3em;
    width: 90%;
  }

  @media (min-width: 1500px) {
    font-size: 2.5em;
    width: 90%;
  }
`

class Hero extends Component {
  render() {
    return (
      <Grid stackable columns="2">
        <Grid.Row>
          <Grid.Column>
            <MainHeader as="h1">
              {this.props.intl.formatMessage({ id: "hero_message" })}
            </MainHeader>
            <Subtitle
              as="h2"
              dangerouslySetInnerHTML={{
                __html: this.props.intl.formatHTMLMessage({
                  id: "hero_subtitle",
                }),
              }}
            />
          </Grid.Column>
          <Grid.Column style={{ paddingRight: "5em" }}>
            <ReactPlayer
              url={this.props.intl.formatMessage({ id: "video_url" })}
              wistiaConfig={{
                options: {
                  videoFoam: true,
                },
              }}
              width="100%"
              height="100%"
            />
          </Grid.Column>
          >
        </Grid.Row>
      </Grid>
    )
  }
}

export default injectIntl(Hero)
